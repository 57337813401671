.Area-Of-Focus-Main{
    color: white;
}



.Area-Of-Focus-Area{
    display: inline-block;
    background-color: rgba(0,0,0, 0.5);
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
}

.Area-Of-Focus-Area h2{
    font-size: 2rem;;
}

@media (max-width:1000px) {
    .Area-Of-Focus-Area h2{
        font-size: 2rem;
    }
}