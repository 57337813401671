.certs-main{
    color: white;
    text-align: center;
}

.certs-title{
    font-size: 4rem;
}

.certs-row{
    display: inline-flex;
    background-color: rgba(0,0,0, 0.5);
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
}

.cert{
    text-align: center;
    font-size: auto;
    width: 20vw;
    padding: 10px;
    margin-bottom: 20px;
}

.cert-title-container{
    width: 15vw;
    height: 12vh;
    text-align: center;
    margin:auto;
}

.cert img{
    width: 20vw;
}

@media (max-width:1000px) {
    .certs-title{
        font-size: 8vw;
    }

    .cert{
        font-size: 10px;
        padding: 5px;
    }
}