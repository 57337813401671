.projects-main{
    color: white;
}

.project{  
    background-color: rgba(0,0,0, 0.75);
    text-align: center;
    margin-bottom: 20px;
}

.project-title {
    font-size: 32pt;
}
.screenshot {
    width: 35vw;
}

.vertScreenShot{
    height: 35vw;
}

.description {
    margin: auto;
    font-size: 16pt;
    width: 80vw;
    padding-bottom: 5px;
}
