.App {
  text-align: center;
}

.Images{
  position: relative;
}

.Me {
  height: 40vmin;
  border-radius: 180px;
  position: absolute;
  transition: opacity 0.75s ease-in-out;
}

.Me:hover {
  opacity: 0;
}

.Logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 180px;
}


.App-header {
  font-style:bold;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Bio{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.5rem;
}

.Roles{
  padding: 10px;
}

.Roles h3{
  font-size: 1.25rem;
}

.Roles a{
  color:white;
  text-decoration: none;
}

.Roles h3:hover {
  background-color: white;
  color: black;
  border-radius: 5px;
}

#main-Div{
  margin-top: 25px;
  font-style:bold;
  background-color: rgba(0,0,0, 0.75);
  width: 100%;
  padding-bottom: 10px;
}



.lines>a {
  display: inline-block;
}

.item{
  padding: 15px;
  color: white;
  text-decoration: none ;
}

.item:hover {
  background-color: white;
  color: black;
  border-radius: 5px;
}

@media (max-width:1000px) {

}